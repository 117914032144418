import HttpService from "./HttpService";

export default class AdmissionService extends HttpService {
    endPoint = "/api/v1/gamma/admissions"

    history(identifier) {
        return this.getAuthTokenRequest().get(this.endPoint + "/history/" + identifier);
    }

    startWorkFlow(data) {
        return this.getAuthTokenRequest().post(this.endPoint + "/startWorkFlow", data);
    }

    getTasks(params) {
        return this.getAuthTokenRequest().get(this.endPoint + "/tasks", {params: params});
    }
}
