import React, {Component} from 'react';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import {I18n} from "react-redux-i18n";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import BpmnUserService from "../services/BpmnUserService";
import AccountService from "../services/AccountService";
import {Menu} from "primereact/menu";

class ProjectToBeProcessed extends Component {
    emptyElement = {
        accountUsername: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.menu = React.createRef();

        this.formikProps = {
            initialValues: this.emptyElement
        };

        this.elementService = new BpmnUserService();
        this.accountService = new AccountService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
    }

    componentDidMount() {
        this.createOptions();
        this.loadAccounts();
        this.setState({loading: true});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order).then(
            response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
        }
    }

    createOptions() {
        const menuModel = [
            {label: I18n.t('DELETE_DATA'), icon: 'pi pi-fw pi-trash', command: () => this.confirmDeleteElement(this.state.selectedElement)}
        ];

        const sortFilterValues = [
            {name: I18n.t('NAME') + ' ' + I18n.t('ASCENDING'), field: 'name', order: Order.ASC},
            {name: I18n.t('NAME') + ' ' + I18n.t('DESCENDING'), field: 'name', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    loadAccounts() {
        const params = new URLSearchParams({
            start: 0,
            size: 0
        });

        this.accountService.getPageParams(params)
            .then(response => {
                    let accounts = []
                    if (response.data.content && response.data.content.list) {
                        for (let i = 0; i < response.data.content.list.length; i++) {
                            let account = response.data.content.list[i];
                            accounts.push({label: account.firstName + ' - ' + account.lastName, value: account.username})
                        }
                    }
                    this.setState({accounts})
                }
            );
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        let searchFilterValue = [{field: 'name', value: this.state.searchFilterValue}];
        this.elementService.getPage(page, rows, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order, searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    openNew() {
        this.setState({
            element: this.emptyElement,
            submitted: false,
            elementDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            elementDialog: false,
            element: this.emptyElement,
        });
    }


    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveElement = (element, {setStatus, setErrors}) => {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        this.elementService.add(element)
            .then((response) => {
                elements.unshift(response.data.content);
                this.setState({
                    elements,
                    elementDialog: false,
                    loadingDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords + 1,
                });
                this.formikProps.initialValues = this.emptyElement;
                this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t("USER_ALREADY_EXIST"), life: 6000});
            });

    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.username)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        let searchValue = [{field: 'name', value: searchFilterValue}];
        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue?.field, sortFilterValue?.order, searchValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000});
            });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
                <Button type="button" label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label={I18n.t('IMPORT_DATA')} chooseLabel="Import" className="p-mr-2 p-d-inline-block"/>
                <Button type="button" label={I18n.t('EXPORT_DATA')} icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV}/>
            </React.Fragment>
        )
    }

    renderFooter() {
        return (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button type="submit" form="formElementDialog" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 p-lg-4">
                    <div className="card widget-expenses">
                        <div className="card-header">
                            <h5>Expenses</h5>
                            <div>
                                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => this.menu.current.toggle(event)}></Button>
                                <Menu ref={this.menu} popup={true} model={[{label: 'Update', icon: 'pi pi-fw pi-refresh'}, {label: 'Edit', icon: 'pi pi-fw pi-pencil'}]}></Menu>
                            </div>
                        </div>
                        <div className="card-subheader p-mb-2 p-pb-3">
                            November 22 - November 29
                        </div>

                        <div className="p-d-flex p-jc-between p-ai-center p-my-2 item ">
                            <div className="p-d-flex p-flex-column">
                                <i className="pi pi-cloud type p-mb-2"></i>
                                <span className="value p-mb-1">$30.247</span>
                                <div>
                                    <span className="subtext p-mr-5">Cloud Infrastructure</span>
                                    <span className="person-tag indigo-bgcolor p-p-1 fs-small p-ml-auto color white-color">Accounting</span>
                                </div>
                            </div>
                            <span className="item-button"><button className="p-link"><i className="pi pi-chevron-right"></i></button></span>
                        </div>
                        <div className="p-d-flex p-jc-between p-ai-center p-my-2 item">
                            <div className="p-d-flex p-flex-column">
                                <i className="pi pi-tag type p-mb-2"></i>
                                <span className="value p-mb-1">$29.550</span>
                                <span className="subtext">General Goods</span>
                            </div>
                            <span className="item-button"><button className="p-link"><i className="pi pi-chevron-right"></i></button></span>
                        </div>
                        <div className="p-d-flex p-jc-between p-ai-center p-my-2 item">
                            <div className="p-d-flex p-flex-column">
                                <i className="pi pi-desktop type p-mb-2"></i>
                                <span className="value p-mb-1">$16.660</span>
                                <span className="subtext">Consumer Electronics</span>
                            </div>
                            <span className="item-button"><button className="p-link"><i className="pi pi-chevron-right"></i></button></span>
                        </div>
                        <div className="p-d-flex p-jc-between p-ai-center p-my-2 item">
                            <div className="p-d-flex p-flex-column">
                                <i className="pi pi-compass type p-mb-2"></i>
                                <span className="value p-mb-1">$5.801</span>
                                <span className="subtext">Incalculables</span>
                            </div>
                            <span className="item-button"><button className="p-link"><i className="pi pi-chevron-right"></i></button></span>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-lg-8">
                    <div className="card ">
                        docs details here
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    const accessToken = state.auth.account.accessToken;
    return {
        locale,
        accessToken
    };
}

export default connect(mapStateToProps)(ProjectToBeProcessed);
