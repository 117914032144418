import HttpService from "./HttpService";

export default class BpmnGroupService extends HttpService {
    endPoint = "/api/v1/core/bpmns/groups"

    addMembership(groupId, data) {
        return this.getAuthTokenRequest().post(this.endPoint + `/${groupId}/memberships`, data);
    }

    deleteMembership(groupId, userId) {
        return this.getAuthTokenRequest().delete(this.endPoint + `/${groupId}/memberships/${userId}`);
    }
}

