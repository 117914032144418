import HttpService from "./HttpService";

export default class BpmnDeploymentService extends HttpService {
    endPoint = "/api/v1/core/bpmns/deployments"

    getDeploymentResources(deploymentId) {
        return this.getAuthTokenRequest().get(this.endPoint + `/${deploymentId}/resources`);
    }

    downloadDeploymentResource(deploymentId, resourceName) {
        resourceName = resourceName.replace('/', '%2F');
        return this.getAuthTokenRequest().get(this.endPoint + `/${deploymentId}/resourcedata/${resourceName}`, {responseType: 'blob'});
    }
}

