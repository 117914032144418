import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import * as Yup from 'yup';
import fileDownload from "js-file-download";
import DocViewer2 from "./DocViewer2";
import BpmnProcessDefinitionService from "../services/BpmnProcessDefinitionService";
import BpmnDeploymentService from "../services/BpmnDeploymentService";


class ProcessDefinition extends Component {
    emptyElement = {
        deploymentKey: '',
        deploymentName: '',
        tenantId: '',
        category: '',
        file: null
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                file: Yup.mixed().required(I18n.t('FIELD_IS_REQUIRED')),
            })
        };

        this.elementService = new BpmnProcessDefinitionService();
        this.deploymentService = new BpmnDeploymentService();

        this.onPage = this.onPage.bind(this);
        this.viewElement = this.viewElement.bind(this);
        this.downloadDeploymentResource = this.downloadDeploymentResource.bind(this);
        this.renderFooterPreview = this.renderFooterPreview.bind(this);
    }


    componentDidMount() {
        this.createOptions();
        this.setState({loading: true});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order).then(
            response => {
                console.log('elements ...', response)
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
        }

        if (prevState.selectedElement !== this.state.selectedElement) {
            this.createOptions();
        }
    }

    downloadDeploymentResource(deploymentId, resourceName) {
        this.setState({
            download: true,
        });
        this.deploymentService.downloadDeploymentResource(deploymentId, resourceName)
            .then((response) => {
                fileDownload(response.data, resourceName);
                this.setState({
                    download: false,
                });
            }).catch(err => {
            this.setState({
                download: false,
            });
            this.toast.show({severity: 'error', summary: I18n.t('DOWNLOAD'), detail: err.response.data.message, life: 6000});
        })
    }

    createOptions() {
        const menuModel = [
            {label: I18n.t('DOWNLOAD'), icon: 'pi pi-fw pi-download', command: () => this.downloadDeploymentResource(this.state.selectedElement.deploymentId, this.state.selectedElement.resource)},
        ];

        if (this.state.selectedElement && !!this.state.selectedElement.diagram) {
            menuModel.push({label: I18n.t('VIEW_DIAGRAM'), icon: 'pi pi-fw pi-eye', command: () => this.viewElement(this.state.selectedElement)})
        }

        const sortFilterValues = [
            {name: I18n.t('NAME') + ' ' + I18n.t('ASCENDING'), field: 'name', order: Order.ASC},
            {name: I18n.t('NAME') + ' ' + I18n.t('DESCENDING'), field: 'name', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        let searchFilterValue = [{field: 'name', value: this.state.searchFilterValue}];
        this.elementService.getPage(page, rows, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order, searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }


    viewElement(element) {
        console.log('element .......',element)
        this.setState({
            viewDialog: true,
            loading: false,
            element: element
        })
    }

    renderFooterPreview() {
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('CLOSE')} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({viewDialog: false})}/>
            </React.Fragment>
        );
    }


    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{I18n.t('PROCESS_DEFINITIONS')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue} onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')} placeholder={I18n.t('SEARCH_DATA')}/>
                 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue} onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
                           options={this.state.sortFilterValues} optionLabel="name" placeholder={I18n.t('SELECT_ORDER')}/>
                </span>
            </div>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el} onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">
                    <DataTable ref={(el) => this.dt = el} value={this.state.elements} selection={this.state.selectedElement} onSelectionChange={(e) => this.setState({selectedElement: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive  selectionMode="single"
                               contextMenuSelection={this.state.selectedElement} onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords} first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>

                        <Column field="key" header={I18n.t('KEY')}/>
                        <Column field="id" header={I18n.t('ID')}/>
                        <Column field="deploymentId" header={I18n.t('DEPLOYMENT_ID')}/>
                        <Column field="version" header={I18n.t('VERSION')}/>
                        <Column field="name" header={I18n.t('NAME')}/>

                    </DataTable>
                </div>

                <Dialog visible={this.state.viewDialog} style={{width: '1050px'}} header={I18n.t('PREVIEW')} footer={this.renderFooterPreview} modal className="p-fluid" closable={false} onHide={() => this.setState({viewDialog: false})}>
                    {this.state.element &&
                    <DocViewer2 uri={this.state.element.diagramResourceUrl} fileName={this.state.element.diagram}/>}
                </Dialog>

            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(ProcessDefinition);


