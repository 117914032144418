import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {PrivateRoute} from "./components/PrivateRoute";
import {Role} from "./helpers/role";
import Dashboard from "./components/Dashboard";
import DashboardAnalytics from "./components/DashboardAnalytics";
import Account from "./components/Account";
import TemplateGroup from "./components/TemplateGroup";
import Template from "./components/Template";
import Rule from "./components/Rule";
import Deployment from "./components/Deployment";
import CoreTranslation from "./components/CoreTranslation";
import ProfileTranslation from "./components/ProfileTranslation";
import ProcessDefinition from "./components/ProcessDefinition";
import BpmnGroup from "./components/BpmnGroup";
import BpmnUser from "./components/BpmnUser";
import Project from "./components/Project";
import ProjectDetails from "./components/ProjectDetails";
import ProjectToBeProcessed from "./components/ProjectToBeProcessed";
import Course from "./components/Course";
import Country from "./components/Country";
import Level from "./components/Level";
import School from "./components/School";
import CoreSettings from "./components/CoreSettings";
import ProfileSettings from "./components/ProfileSettings";
import UserSettings from "./components/UserSettings";
import Appointment from "./components/Appointment";

const AppRoute = () => {

    return (
        <div className="layout-content">
            <PrivateRoute roles={[Role.ROLE_USER]} path={["/", "/projects"]} exact component={Project}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/projects/details/:identifier" exact component={ProjectDetails}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/projects/tobeprocessed" exact component={ProjectToBeProcessed}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/dashboard/stats" exact component={Dashboard}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/dashboard/analytics" component={DashboardAnalytics}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/accounts" component={Account}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/templates" exact component={Template}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/templates/groups" exact component={TemplateGroup}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/rules" exact component={Rule}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/deployments" exact component={Deployment}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/translations/core" exact component={CoreTranslation}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/translations/profile" exact component={ProfileTranslation}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/process-definitions" exact component={ProcessDefinition}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/process-groups" exact component={BpmnGroup}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/process-users" exact component={BpmnUser}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/courses" exact component={Course}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/countries" exact component={Country}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/levels" exact component={Level}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/schools" exact component={School}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/core" exact component={CoreSettings}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/profile" exact component={ProfileSettings}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/user" exact component={UserSettings}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/appointments" exact component={Appointment}/>
        </div>
    );

}

export default AppRoute;
