import React, {Component} from 'react';
import {Button} from 'primereact/button';
import {I18n} from "react-redux-i18n";
import {Dropdown} from "primereact/dropdown";
import {Formik} from "formik";
import * as Yup from "yup";
import {classNames} from "primereact/utils";
import {countryListData, getFormErrorMessage, isFormFieldInvalid, localeEnUSOptions, localeFrFROptions} from "../helpers/utils";
import {Dialog} from "primereact/dialog";
import CountryService from "../services/CountryService";
import {Card} from "primereact/card";
import {Divider} from "primereact/divider";
import {connect} from "react-redux";
import AdmissionService from "../services/AdmissionService";
import {Link} from "react-router-dom";
import {Toast} from 'primereact/toast';
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import moment from 'moment';
import {addLocale} from 'primereact/api';

class Project extends Component {
    emptyElement = {
        countryCode: '',
        supportType: '',
        countryHomeCode: '',
        birthday: '',
        placeOfBirth: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            elementDialog: false,
            elements: [],
        };

        this.formikProps = {
            initialValues: this.emptyElement
        };

        addLocale('fr-FR', localeFrFROptions);
        addLocale('en-US', localeEnUSOptions);

        this.countryService = new CountryService();
        this.admissionService = new AdmissionService();

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.startWorkFlow = this.startWorkFlow.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.searchCountry = this.searchCountry.bind(this);
        this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
        this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
        this.buildValidationSchema = this.buildValidationSchema.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.loadTypesEnums();
            this.loadCountries();
            this.formikProps.validationSchema = this.buildValidationSchema()
        }
    }

    componentDidMount() {
        this.formikProps.validationSchema = this.buildValidationSchema()
        this.loadTypesEnums();
        this.loadCountries();
        this.setState({countriesList: countryListData});
        this.setState({loading: true});
        const params = new URLSearchParams({
            start: 0,
            //size: this.pageSize,
            //sortFilter: this.state.sortFilter
        });
        this.admissionService.getPageParams(params)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({loading: false});
            });
    }

    buildValidationSchema() {
        let validationShape = {
            supportType: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
            countryCode: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
        };

        if (!this.props.data.timezone) {
            validationShape.countryHomeCode = Yup.mixed().required(I18n.t('FIELD_IS_REQUIRED'))
        }

        if (!this.props.data.birthday) {
            validationShape.birthday = Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
        }

        if (!this.props.data.placeOfBirth) {
            validationShape.placeOfBirth = Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
        }

        return Yup.object().shape(validationShape);
    }

    searchCountry(event) {
        let filteredCountries;
        if (!event.query.trim().length) {
            filteredCountries = [...this.state.countriesList];
        } else {
            filteredCountries = this.state.countriesList.filter((country) => {
                return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        this.setState({filteredCountries});
    }

    monthNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{lineHeight: 1}}/>;
    }

    yearNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{lineHeight: 1}}/>;
    }

    startWorkFlow = (element, {setStatus, setErrors}) => {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        element.countryHomeCode = element.countryHomeCode.code

        this.admissionService.startWorkFlow(element)
            .then((response) => {
                elements.unshift(response.data.content);
                this.setState({
                    elements,
                    elementDialog: false,
                    loadingDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords + 1,
                });
                this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
            });

    }

    openNew() {
        this.setState({
            elementDialog: true
        });
    }

    loadTypesEnums() {
        this.typesEnums = [
            {name: I18n.t('INTERVIEW_AND_ORIENTATION'), code: 'INTERVIEW_AND_ORIENTATION'},
            {name: I18n.t('CONTACTING_SCHOOLS'), code: 'CONTACTING_SCHOOLS'},
            {name: I18n.t('GUARANTEED_ADMISSION'), code: 'GUARANTEED_ADMISSION'}
        ];
    }

    loadCountries() {
        this.countryService.getAll().then(
            response => {
                let list = response.data.content ? response.data.content : [];
                let countries = []
                for (let i = 0; i < list.length; i++) {
                    let country = list[i];
                    countries.push({label: I18n.t(country.labelCode), id: country.code})
                }
                this.setState({countries})
            }
        );
    }


    hideDialog() {
        this.setState({
            elementDialog: false,
            element: this.emptyElement,
        });
    }

    renderFooter() {
        return (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button type="submit" form="formElementDialog" loading={this.state.loadingDialog} label={I18n.t('CONTINUE')} iconPos="right" icon="pi pi-arrow-right" className="p-button-text"/>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="p-col-12">
                <Toast ref={(el) => this.toast = el}/>
                <div className="card widget-expenses">
                    <div className="card-header">
                        <Button type="button" label={I18n.t('START_NEW_PROJECT')} icon="pi pi-arrow-circle-right" className="p-button-help p-mr-2" onClick={this.openNew}/>
                    </div>
                    <Divider/>
                    {this.state.elements.map((row, index) => (
                        <Link key={index} to={`/projects/details/${row.identifier}`} className="p-d-flex p-jc-between p-ai-center p-my-2 item">
                            <div className="p-d-flex p-flex-column">
                                <span className="p-p-1 fs-large color indigo-color p-text-uppercase p-text-bold">{I18n.t(row.projectType)}</span>
                                <span className="value p-mb-1">{I18n.t("COUNTRY")}: {I18n.t(row.countryCode)}</span>
                                <div>
                                    <span className="subtext p-mr-5"> {I18n.t("CREATED_AT")}: {Intl.DateTimeFormat(this.props.locale, {dateStyle: 'full', timeStyle: 'medium'}).format(new Date(row.createdOn))}</span>
                                    {!row.closed && <span className="p-p-1 fs-small p-ml-auto color green-color p-text-bold">{I18n.t("ONGOING_PROJECT")}</span>}
                                    {row.closed && <span className="p-p-1 fs-small p-ml-auto color brown-color p-text-bold">{I18n.t("CLOSED_PROJECT")}</span>}
                                </div>
                            </div>
                            <span className="item-button"><button className="p-link"><i className="pi pi-chevron-right"></i></button></span>
                        </Link>
                    ))}
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '450px'}} footer={this.renderFooter} modal className="p-fluid" closable={false} onHide={this.hideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialValues}
                        validationSchema={this.formikProps.validationSchema}
                        onSubmit={this.startWorkFlow}
                    >
                        {props => {
                            console.log(props)
                            return <form id="formElementDialog" onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>
                                {(!this.props.data.timezone || !this.props.data.birthday || !this.props.data.placeOfBirth) && <div className="card">
                                    <h5>{I18n.t('ABOUT_YOU')}</h5>
                                    {!this.props.data.timezone && <div className="p-float-label p-field">
                                        <AutoComplete
                                            id="countryHomeCode"
                                            name="countryHomeCode"
                                            value={props.values.countryHomeCode}
                                            suggestions={this.state.filteredCountries}
                                            completeMethod={this.searchCountry}
                                            field="name"
                                            onChange={(event) => props.setFieldValue('countryHomeCode', event.value)}
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'countryHomeCode')})}
                                        />
                                        {getFormErrorMessage(props, 'countryHomeCode')}
                                        <label htmlFor="countryHomeCode" className={classNames({'p-error': isFormFieldInvalid(props, 'countryHomeCode')})}>{I18n.t('HOME_COUNTRY')}</label>
                                    </div>}

                                    {!this.props.data.birthday && <div className="p-field" style={{marginTop: '10px'}}>
                                        <label htmlFor="birthday" className={classNames({'p-error': isFormFieldInvalid(props, 'birthday')})}>{I18n.t('BIRTHDAY_DATE')}</label>
                                        <Calendar
                                            id="birthday"
                                            value={props.values.birthday && new Date(props.values.birthday)}
                                            onChange={(event) => props.setFieldValue('birthday', moment(event.value).format('YYYY-MM-DD'))}
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'birthday')})}
                                            monthNavigator yearNavigator yearRange="1900:2030"
                                            monthNavigatorTemplate={this.monthNavigatorTemplate}
                                            yearNavigatorTemplate={this.yearNavigatorTemplate}
                                            dateFormat={this.props.locale === "fr-FR" ? "dd/mm/yy" : "mm/dd/yy"}
                                            locale={this.props.locale}
                                            showIcon/>
                                        {getFormErrorMessage(props, 'birthday')}
                                    </div>}

                                    {!this.props.data.placeOfBirth && <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                        <InputText
                                            name="placeOfBirth"
                                            value={props.values.placeOfBirth}
                                            onChange={props.handleChange}
                                            id="placeOfBirth"
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'placeOfBirth')})}
                                        />
                                        {getFormErrorMessage(props, 'placeOfBirth')}
                                        <label htmlFor="placeOfBirth" className={classNames({'p-error': isFormFieldInvalid(props, 'placeOfBirth')})}>{I18n.t('PLACE_OF_BIRTH')}</label>
                                    </div>}
                                </div>}

                                <div className="card">
                                    <h5>{I18n.t('YOUR_PROJECT')}</h5>
                                    <div className="p-float-label p-field">
                                        <Dropdown
                                            name="countryCode"
                                            id="countryCode"
                                            value={props.values.countryCode}
                                            options={this.state.countries}
                                            onChange={props.handleChange}
                                            optionLabel="label"
                                            optionValue="id"
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'countryCode')})}
                                        />
                                        {getFormErrorMessage(props, 'countryCode')}
                                        <label htmlFor="countryCode" className={classNames({'p-error': isFormFieldInvalid(props, 'countryCode')})}>{I18n.t('STUDY_COUNTRY')}</label>
                                    </div>

                                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                        <Dropdown
                                            name="supportType"
                                            id="supportType"
                                            value={props.values.supportType}
                                            onChange={props.handleChange}
                                            options={this.typesEnums}
                                            optionLabel="name"
                                            optionValue="code"
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'supportType')})}
                                        />
                                        {getFormErrorMessage(props, 'supportType')}
                                        <label htmlFor="supportType" className={classNames({'p-error': isFormFieldInvalid(props, 'supportType')})}>{I18n.t('SCHOOL_SUPPORT_TYPE')}</label>
                                    </div>

                                    <div className="p-float-label p-field">
                                        <Card>
                                            {props.values.supportType === "INTERVIEW_AND_ORIENTATION" &&
                                            <div>
                                                <div dangerouslySetInnerHTML={{__html: I18n.t('SUPPORT_INTERVIEW_DESCRIPTION')}}/>
                                                <Divider/>
                                                <p><b> {I18n.t('PRICE_OF_SERVICE')}: {this.props.settings.FR_CONSULTING_PRICE} FCFA</b></p>
                                            </div>
                                            }
                                            {props.values.supportType === "CONTACTING_SCHOOLS" &&
                                            <div>
                                                <div dangerouslySetInnerHTML={{__html: I18n.t('SUPPORT_SCHOOL_DESCRIPTION')}}/>
                                                <Divider/>
                                                <p><b> {I18n.t('PRICE_OF_SERVICE')}: {this.props.settings.FR_SCHOOL_PRICE} FCFA</b></p>
                                            </div>
                                            }
                                            {props.values.supportType === "GUARANTEED_ADMISSION" &&
                                            <div>
                                                <div dangerouslySetInnerHTML={{__html: I18n.t('SUPPORT_ADMISSION_DESCRIPTION')}}/>
                                                <Divider/>
                                                <p><b> {I18n.t('PRICE_OF_SERVICE')}: {this.props.settings.FR_ADMISSION_PRICE} FCFA</b></p>
                                            </div>
                                            }
                                        </Card>
                                    </div>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    const settings = state.settings;
    const accessToken = state.auth.account.accessToken;
    const data = state.auth.account.data;
    return {
        locale,
        accessToken,
        settings,
        data
    };
}

export default connect(mapStateToProps)(Project);
