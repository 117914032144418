import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {Checkbox} from "primereact/checkbox";
import {Chips} from "primereact/chips";
import {Formik} from 'formik';
import {getFormErrorMessage, isFormFieldInvalid, price, string_to_slug} from "../helpers/utils";
import LevelService from "../services/LevelService";
import * as Yup from "yup";
import {InputTextarea} from "primereact/inputtextarea";
import {InputNumber} from "primereact/inputnumber";
import CountryService from "../services/CountryService";
import CourseService from "../services/CourseService";
import {MultiSelect} from "primereact/multiselect";
import {connect} from "react-redux";

class Level extends Component {
    emptyElement = {
        labelCode: '',
        level: '',
        description: '',
        countryCode: '',
        elements: [],
        coursesIds: [],
        applicationFees: price
    };

    emptyElementDescription = {
        order: '',
        required: false,
        labelCode: '',
        changeMessage: '',
        type: '',
        code: '',
        values: []
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            elementDescriptionDialog: false,
            deleteElementDialog: false,
            deleteElementDescriptionDialog: false,
            deleteElementsDialog: false,
            deleteElementsDescriptionDialog: false,
            element: this.emptyElement,
            elementDescription: this.emptyElementDescription,
            selectedElement: null,
            selectedElements: [],
            categories: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            submittedDescription: false,
            submittedElement: false,
            loadingDialog: false,
            loadingDescriptionDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            initialElementValues: this.emptyElementDescription
        };

        this.parentProps = null

        this.elementService = new LevelService();
        this.courseService = new CourseService();
        this.countryService = new CountryService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.leftDescriptionToolbarTemplate = this.leftDescriptionToolbarTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.saveDescriptionElement = this.saveDescriptionElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.editElementDescription = this.editElementDescription.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.deleteElementDescription = this.deleteElementDescription.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.confirmDeleteDescriptionSelected = this.confirmDeleteDescriptionSelected.bind(this);
        this.deleteSelectedElements = this.deleteSelectedElements.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputDescriptionChange = this.onInputDescriptionChange.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementDescriptionDialog = this.hideDeleteElementDescriptionDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.openNewDescription = this.openNewDescription.bind(this);
        this.hideDescriptionDialog = this.hideDescriptionDialog.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.onRowReorder = this.onRowReorder.bind(this);
    }


    componentDidMount() {
        this.createOptions();
        this.loadPriceEnums();
        this.loadCountries();
        this.loadDescriptions();
        this.loadCourses();
        this.setState({loading: true});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order).then(
            response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
            this.loadPriceEnums();
            this.loadCourses();
            this.loadDescriptions();
        }
    }

    loadCountries() {
        this.countryService.getAll().then(
            response => {
                let list = response.data.content ? response.data.content : [];
                let countries = []
                for (let i = 0; i < list.length; i++) {
                    let country = list[i];
                    countries.push({label: I18n.t(country.labelCode), id: country.code})
                }
                this.setState({countries})
            }
        );
    }

    loadCourses() {
        this.courseService.getAll().then(
            response => {
                let list = response.data.content ? response.data.content : [];
                let courses = []
                for (let i = 0; i < list.length; i++) {
                    let course = list[i];
                    courses.push({label: I18n.t(course.labelCode), id: course.id})
                }
                this.setState({courses})
            }
        );
    }

    loadDescriptions() {
        this.descriptionTypes = [
            {name: I18n.t('INTEGER'), value: 'INTEGER'},
            {name: I18n.t('REAL'), value: 'REAL'},
            {name: I18n.t('STRING'), value: 'STRING'},
            {name: I18n.t('FILE'), value: 'FILE'},
            {name: I18n.t('TEXT'), value: 'TEXT'},
            {name: I18n.t('LIST_STRING'), value: 'LIST_STRING'},
            {name: I18n.t('LIST_INTEGER'), value: 'LIST_INTEGER'},
            {name: I18n.t('LIST_REAL'), value: 'LIST_REAL'},
            {name: I18n.t('DATE'), value: 'DATE'},
            {name: I18n.t('INTEGER_INTERVAL'), value: 'INTEGER_INTERVAL'},
            {name: I18n.t('REAL_INTERVAL'), value: 'REAL_INTERVAL'},
            {name: I18n.t('DATE_INTERVAL'), value: 'DATE_INTERVAL'},
        ];
    }

    loadPriceEnums() {
        this.priceEnums = [
            {name: I18n.t('XAF'), code: 'XAF'},
            {name: I18n.t('XOF'), code: 'XOF'},
            {name: I18n.t('USD'), code: 'USD'},
            {name: I18n.t('EUR'), code: 'EUR'},
        ];
    }

    createOptions() {
        const menuModel = [
            {label: I18n.t('EDIT_DATA'), icon: 'pi pi-fw pi-pencil', command: () => this.editElement(this.state.selectedElement)},
            {label: I18n.t('DELETE_DATA'), icon: 'pi pi-fw pi-trash', command: () => this.confirmDeleteElement(this.state.selectedElement)}
        ];

        const sortFilterValues = [
            {name: I18n.t('SUBCATEGORY_NAME_FR') + ' ' + I18n.t('ASCENDING'), field: 'subcategory.nameFr', order: Order.ASC},
            {name: I18n.t('SUBCATEGORY_NAME_FR') + ' ' + I18n.t('DESCENDING'), field: 'subcategory.nameFr', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        let searchFilterValue = [{field: 'name', value: this.state.searchFilterValue}];
        this.elementService.getPage(page, rows, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order, searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    openNew() {
        this.setState({
            element: this.emptyElement,
            submitted: false,
            elementDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            elementDialog: false,
            element: this.emptyElement
        });
    }

    openNewDescription() {
        this.setState({
            elementDescription: this.emptyElementDescription,
            submittedDescription: false,
            elementDescriptionDialog: true
        });
    }

    hideDescriptionDialog() {
        this.setState({
            submittedDescription: false,
            elementDescriptionDialog: false,
            elementDescription: this.emptyElementDescription
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementDescriptionDialog() {
        this.setState({deleteElementDescriptionDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveElement = (element, {setStatus, setErrors}) => {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        if (element.id) { //may be update
            this.elementService.edit(element.id, element)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    elements[index] = response.data.content;
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    console.log("---------error", error)
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        } else { //may be creation
            this.elementService.add(element)
                .then((response) => {
                    elements.unshift(response.data.content);
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                        totalRecords: this.state.totalRecords + 1,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        }
    }

    saveDescriptionElement = (element, {setStatus, setErrors, resetForm}) => {
        this.setState({
            loadingDescriptionDialog: true,
        });
        let elements = this.parentProps.values.elements ? this.parentProps.values.elements : [];
        if (element.code) { //may be update
            const index = this.findIndexDescriptionByCode(element.code, elements);
            elements[index] = element;
            this.parentProps.setFieldValue('elements', elements)
            this.setState({
                loadingDescriptionDialog: false,
                elementDescriptionDialog: false
            })
            this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
        } else { //may be creation
            this.setState({
                loadingDescriptionDialog: true,
            })
            element.code = string_to_slug(element.labelCode, '-', true)
            elements.unshift(element)
            this.parentProps.setFieldValue('elements', elements)
            this.setState({
                loadingDescriptionDialog: false,
                elementDescriptionDialog: false
            })
            this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
        }
        resetForm()
    }

    editElement(element) {
        this.formikProps.initialValues = element;
        this.setState({
            elementDialog: true
        });
    }

    editElementDescription(element) {
        this.formikProps.initialElementValues = element;
        this.setState({
            elementDescriptionDialog: true
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                let selectedElements = this.state.selectedElements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false,
                    selectedElements
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    deleteElementDescription() {
        this.setState({
            loadingDescriptionDialog: true,
        });
        let elements = this.parentProps.values.elements ? this.parentProps.values.elements : [];
        elements = elements.filter(val => val.code !== this.state.elementDescription.code);
        this.parentProps.setFieldValue('elements', elements)
        this.setState({
            deleteElementDescriptionDialog: false,
            elementDescription: this.emptyElementDescription,
            loadingDescriptionDialog: false
        });
        this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    findIndexDescriptionByCode(code, elements) {
        let list = elements ? elements : [];
        let index = -1;
        for (let i = 0; i < list.length; i++) {
            if (list[i].code === code) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    confirmDeleteDescriptionSelected(elementDescription) {
        this.setState({elementDescription, deleteElementDescriptionDialog: true});
    }

    deleteSelectedElements() {
        this.setState({
            loadingDialog: true,
        });
        let dataIds = this.state.selectedElements.map((el) => el.id);
        this.elementService.deleteMany(dataIds)
            .then(() => {
                let elements = this.state.elements.filter(val => !this.state.selectedElements.includes(val));
                this.setState({
                    totalRecords: this.state.totalRecords - this.state.selectedElements.length,
                    elements,
                    deleteElementsDialog: false,
                    selectedElements: [],
                    loadingDialog: false,
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementsDialog: false,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = {...this.state.element};
        element[`${name}`] = val;
        this.setState({element});
    }

    onInputDescriptionChange(e, name) {
        let val;
        if (name === 'required') {
            val = e.checked
        } else if (name === 'values') {
            val = e.value
        } else {
            val = (e.target && e.target.value) || '';
        }
        let elementDescription = {...this.state.elementDescription};
        elementDescription[`${name}`] = val;
        this.setState({elementDescription});
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        let searchValue = [{field: 'name', value: searchFilterValue}];
        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue?.field, sortFilterValue?.order, searchValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list,
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000});
            });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
                <Button type="button" label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
            </React.Fragment>
        )
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editElementDescription(rowData)}/>
                <Button type="button" icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteDescriptionSelected(rowData)}/>
            </React.Fragment>
        );
    }

    leftDescriptionToolbarTemplate(parentProps) {
        this.parentProps = parentProps
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_ADMISSION_ELEMENT')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNewDescription}/>
            </React.Fragment>
        )
    }

    onRowReorder(e) {
        let element = {...this.state.element};
        element.elements = e.value;
        this.setState({element});
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{I18n.t('ACADEMIC_LEVELS')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue} onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')} placeholder={I18n.t('SEARCH_DATA')}/>
                 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue} onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
                           options={this.state.sortFilterValues} optionLabel="name" placeholder={I18n.t('SELECT_ORDER')}/>
                </span>
            </div>
        );
        const elementDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button type="submit" form="formElementDialog" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
            </React.Fragment>
        );

        const elementDialogDescriptionFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDescriptionDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDescriptionDialog}/>
                <Button type="submit" form="formElementDescriptionDialog" loading={this.state.loadingDescriptionDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
            </React.Fragment>
        );
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );

        const deleteElementDescriptionDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDescriptionDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDescriptionDialog}/>
                <Button type="button" loading={this.state.loadingDescriptionDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElementDescription}/>
            </React.Fragment>
        );

        const deleteElementsDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementsDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedElements}/>
            </React.Fragment>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el} onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}/>
                    <DataTable ref={(el) => this.dt = el} value={this.state.elements} selection={this.state.selectedElements} onSelectionChange={(e) => this.setState({selectedElements: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive
                               contextMenuSelection={this.state.selectedElement} onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords} first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>
                        <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>
                        <Column field="labelCode" header={I18n.t('LABEL_CODE')}/>
                        <Column field="level" header={I18n.t('LEVEL')}/>
                        <Column field="countryCode" header={I18n.t('COUNTRY_CODE')}/>
                    </DataTable>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '550px'}} header={I18n.t('DETAILS')} modal className="p-fluid" footer={elementDialogFooter} closable={false} onHide={this.hideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialValues}
                        validationSchema={Yup.object().shape({
                            labelCode: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                            level: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                        })}
                        onSubmit={this.saveElement}
                    >
                        {props => {
                            console.log(props)
                            return <form id="formElementDialog" onKeyDown={(event) => event.keyCode === 1333 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        autoFocus
                                        name="labelCode"
                                        value={props.values.labelCode}
                                        onChange={props.handleChange}
                                        id="labelCode"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "labelCode")})}
                                    />
                                    {getFormErrorMessage(props, 'labelCode')}<label htmlFor="labelCode">{I18n.t('LABEL_CODE')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        type="number"
                                        name="level"
                                        value={props.values.level}
                                        onChange={props.handleChange}
                                        id="level"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "level")})}
                                    />
                                    {getFormErrorMessage(props, 'level')}<label htmlFor="level">{I18n.t('LEVEL')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputTextarea
                                        name="description"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        id="description"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "description")})}
                                    />
                                    {getFormErrorMessage(props, 'description')}<label htmlFor="level">{I18n.t('DESCRIPTION')}</label>
                                </div>

                                <div className="p-grid p-fluid" style={{marginTop: '10px'}}>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <Button style={{width: '100%'}} className={classNames({'p-error': isFormFieldInvalid(props, 'applicationFees', 'etprice')})} label={I18n.t('APPLICATION_FEES')}/>
                                            <InputNumber
                                                name="applicationFees"
                                                mode="decimal"
                                                minFractionDigits={2}
                                                value={props.values.applicationFees.etprice}
                                                onChange={(event) => props.setFieldValue('applicationFees.etprice', event.value)}
                                                id="applicationFees"
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, 'applicationFees', 'etprice')})}
                                            />
                                            {getFormErrorMessage(props, 'applicationFees', 'etprice')}
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <Button className={classNames({'p-error': isFormFieldInvalid(props, 'applicationFees', 'etprice')})} label={I18n.t('CURRENCY')}/>
                                            <Dropdown
                                                name="price-currency"
                                                id="price-currency"
                                                value={props.values.applicationFees.priceCurrencyEnum}
                                                options={this.priceEnums}
                                                onChange={(event) => props.setFieldValue('applicationFees.priceCurrencyEnum', event.value)}
                                                optionLabel="name"
                                                optionValue="code"
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, 'applicationFees', 'priceCurrencyEnum')})}
                                            />
                                            {getFormErrorMessage(props, 'applicationFees', 'priceCurrencyEnum')}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Dropdown
                                        filter showClear filterBy="countryCode"
                                        name="countryCode"
                                        id="countryCode"
                                        value={props.values.countryCode}
                                        options={this.state.countries}
                                        onChange={props.handleChange}
                                        optionLabel="label"
                                        optionValue="id"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'countryCode')})}
                                    />
                                    {getFormErrorMessage(props, 'countryCode')}
                                    <label htmlFor="countryCode" className={classNames({'p-error': isFormFieldInvalid(props, 'countryCode')})}>{I18n.t('COUNTRY')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <MultiSelect
                                        id='coursesIds'
                                        name="coursesIds"
                                        value={props.values.coursesIds}
                                        options={this.state.courses}
                                        filter
                                        onChange={(event) => props.setFieldValue('coursesIds', event.value)}
                                        optionLabel="label"
                                        optionValue="id"
                                        display="chip"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'coursesIds')})}
                                    />
                                    {getFormErrorMessage(props, 'coursesIds')}
                                    <label htmlFor="coursesIds" className={classNames({'p-error': isFormFieldInvalid(props, 'coursesIds')})}>{I18n.t('COURSES')}</label>
                                </div>

                                <div className="card">
                                    <Toolbar className="p-mb-4" left={this.leftDescriptionToolbarTemplate(props)}/>
                                    <DataTable reorderableColumns ref={(el) => this.dt = el} value={props.values.elements}
                                               dataKey="code" paginator rows={20}
                                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                                               onRowReorder={this.onRowReorder}>
                                        <Column rowReorder style={{width: '3em'}}/>
                                        <Column field="labelCode" header={I18n.t('LABEL_CODE')}/>
                                        <Column field="type" header={I18n.t('TYPE')}/>
                                        <Column body={this.actionBodyTemplate}/>
                                    </DataTable>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>

                <Dialog visible={this.state.elementDescriptionDialog} style={{width: '450px'}} header={I18n.t('DETAILS')} modal className="p-fluid" footer={elementDialogDescriptionFooter} closable={false} onHide={this.hideDescriptionDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialElementValues}
                        validationSchema={Yup.object().shape({
                            labelCode: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                            type: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                        })}
                        onSubmit={this.saveDescriptionElement}
                    >
                        {props => {
                            console.log(props)
                            return <form id="formElementDescriptionDialog" onKeyDown={(event) => event.keyCode === 1311 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        autoFocus
                                        name="labelCode"
                                        value={props.values.labelCode}
                                        onChange={props.handleChange}
                                        id="labelCode"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "labelCode")})}
                                    />
                                    {getFormErrorMessage(props, 'labelCode')}<label htmlFor="labelCode">{I18n.t('LABEL_CODE')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Dropdown
                                        name="type"
                                        id="type"
                                        value={props.values.type}
                                        options={this.descriptionTypes}
                                        onChange={props.handleChange}
                                        optionLabel="name"
                                        optionValue="value"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'type')})}
                                    />
                                    {getFormErrorMessage(props, 'type')}
                                    <label htmlFor="type" className={classNames({'p-error': isFormFieldInvalid(props, 'type')})}>{I18n.t('TYPE')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Chips
                                        id="values"
                                        name="values"
                                        value={props.values.values}
                                        onChange={props.handleChange}
                                        separator=","
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'values')})}
                                    />
                                    {getFormErrorMessage(props, 'values')}
                                    <label htmlFor="values" className={classNames({'p-error': isFormFieldInvalid(props, 'values')})}>{I18n.t('VALUES')}</label>
                                </div>

                                <div className="p-field-checkbox" style={{marginTop: '10px'}}>
                                    <Checkbox
                                        id="required"
                                        value={props.values.required}
                                        name="required"
                                        onChange={props.handleChange}
                                        checked={props.values.required}>
                                    </Checkbox>
                                    <label htmlFor="required">{I18n.t('FIELD_IS_REQUIRED')}</label>
                                </div>

                            </form>
                        }}
                    </Formik>

                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={I18n.t(this.state.element.labelCode)}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementDescriptionDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDescriptionDialogFooter} closable={false} onHide={this.hideDeleteElementDescriptionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.elementDescription && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={I18n.t(this.state.elementDescription.labelCode)}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementsDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementsDialogFooter} closable={false} onHide={this.hideDeleteElementsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.selectedElements.length && <Translate dangerousHTML value="DELETE_MANY_WARNING" count={this.state.selectedElements.length}/>}
                    </div>
                </Dialog>
            </div>
        );
    }

}

function mapStateToProps(state) {
    const
        locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(Level);


