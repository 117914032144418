import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from "primereact/dropdown";
import {connect} from "react-redux";
import {Formik} from 'formik';
import {getFormErrorMessage, isFormFieldInvalid} from "../helpers/utils";
import * as Yup from "yup";
import {I18n} from "react-redux-i18n";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3'
import AppointmentService from "../services/AppointmentService";
import {InputNumber} from "primereact/inputnumber";
import {Toolbar} from "primereact/toolbar";
import CountryService from "../services/CountryService";

class Appointment extends Component {
    emptyElement = {
        start: '',
        end: '',
        placesAvailable: 1
    };

    constructor(props) {
        super(props);

        this.state = {
            events: [],
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                placesAvailable: Yup.number().positive(I18n.t('POSITIVE_NUMBER_REQUIRED')).integer().required(I18n.t('FIELD_IS_REQUIRED'))
            })
        };

        this.elementService = new AppointmentService();
        this.countryService = new CountryService();
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);

        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
    }

    componentDidMount() {
        this.loadCountries();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.loadCountries();
        }
    }

    loadCountries() {
        this.countryService.getAll().then(
            response => {
                let list = response.data.content ? response.data.content : [];
                let countries = []
                for (let i = 0; i < list.length; i++) {
                    let country = list[i];
                    countries.push({code: I18n.t(country.labelCode), timezone: country.timezone})
                }
                this.setState({countries})
            }
        );
    }

    handleDateSelect = (selectInfo) => {
        if (!this.state.selectedCountry) {
            this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t("COUNTRY_REQUIRED_TO_CONTINUE"), life: 6000});
            return
        }
        let element = this.emptyElement
        element.start = selectInfo.start
        element.end = selectInfo.end
        this.formikProps.initialValues = element;
        this.setState({
            elementDialog: true
        });
    }

    handleEventClick = (clickInfo) => {
        this.setState({
            element: clickInfo.event,
            deleteElementDialog: true
        });
    }

    handleCountryChange = (selectedCountry) => {
        this.setState({selectedCountry: selectedCountry, loading: true})
        const params = new URLSearchParams({
            data_code: selectedCountry.code
        });
        this.elementService.getPageParams(params)
            .then(response => {
                let elements = response.data.content ? response.data.content : [];
                let events = []
                for (let i = 0; i < elements.length; i++) {
                    let element = elements[i];
                    events.push({id: element.id, title: I18n.t("RESERVED"), start: element.start, end: element.end})
                }
                this.setState({events, loading: false})
            })
            .catch(error => {
                this.setState({loading: false});
            });
    }

    hideDialog() {
        this.setState({
            elementDialog: false,
            element: this.emptyElement
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveElement = (element, {setStatus, setErrors}) => {
        let events = [...this.state.events];
        this.setState({
            loadingDialog: true,
        });

        if (element.id) { //may be update
            this.elementService.edit(element.id, events)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    events[index] = response.data.content;
                    this.setState({
                        events,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        } else { //may be creation
            element.data = this.state.selectedCountry
            this.elementService.add(element)
                .then((response) => {
                    events.unshift({id: response.data.content.id, title: I18n.t("RESERVED"), start: response.data.content.start, end: response.data.content.end});
                    this.setState({
                        events,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        }
    }

    editElement(element) {
        this.formikProps.initialValues = element;
        this.setState({
            elementDialog: true
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let events = this.state.events.filter(val => val.id !== this.state.element.id);
                this.setState({
                    events,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false,
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }


    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <div className="p-float-label p-field ">
                    <Dropdown
                        style={{width: '20em'}}
                        name="countryCode"
                        id="countryCode"
                        value={this.state.selectedCountry}
                        options={this.state.countries}
                        onChange={(e) => this.handleCountryChange(e.value)}
                        optionLabel="code"
                    />
                    <label htmlFor="countryCode">{I18n.t('STUDY_COUNTRY')}</label>
                </div>
            </React.Fragment>
        )
    }


    renderFooterPreview() {
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('CLOSE')} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({previewDialog: false})}/>
            </React.Fragment>
        );
    }

    renderFooter() {
        return (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button type="submit" form="formElementDialog" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
            </React.Fragment>
        );
    }

    render() {
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );

        let eventTimeFormat={ hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}/>
                <div className="card" style={{width: '60%', display: 'inline-block'}}>
                    <FullCalendar
                        eventTimeFormat={eventTimeFormat}
                        timeZone={this.state.selectedCountry?.timezone}
                        slotDuration='01:00'
                        expandRows
                        weekends={false}
                        allDaySlot={false}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        locale={this.props.locale}
                        events={this.state.events}
                        initialView='timeGridWeek'
                        plugins={[luxonPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay'}}
                        select={this.handleDateSelect}
                        eventClick={this.handleEventClick}
                        loading={this.state.loading}
                    />
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '350px'}} header={I18n.t('DETAILS')} modal footer={this.renderFooter} className="p-fluid" closable={false} onHide={this.hideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialValues}
                        validationSchema={this.formikProps.validationSchema}
                        onSubmit={this.saveElement}
                    >
                        {props => {
                            console.log(props)
                            return <form id="formElementDialog" onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <span className="value p-mb-1"><span className="p-text-bold">{I18n.t("START_DATE")}</span>: {Intl.DateTimeFormat(this.props.locale, {dateStyle: 'full', timeStyle: 'short'}).format(props.values.start)}</span>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <span className="value p-mb-1"><span className="p-text-bold">{I18n.t("END_DATE")}</span>: {Intl.DateTimeFormat(this.props.locale, {dateStyle: 'full', timeStyle: 'short'}).format(props.values.end)}</span>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputNumber
                                        name="placesAvailable"
                                        value={props.values.placesAvailable}
                                        onValueChange={props.handleChange}
                                        id="placesAvailable"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "placesAvailable")})}
                                    />
                                    {getFormErrorMessage(props, 'placesAvailable')}<label htmlFor="placesAvailable">{I18n.t('NUMBER_OF_PLACES')}</label>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && I18n.t('DELETE_WARNING')}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(Appointment);


