import React, {Component} from 'react';
import {connect} from "react-redux";
import {Timeline} from "primereact/timeline";
import classNames from "classnames";
import {Card} from "primereact/card";
import AdmissionService from "../services/AdmissionService";
import {I18n} from "react-redux-i18n";
import {Button} from "primereact/components/button/Button";

class ProjectDetails extends Component {

    constructor(props) {
        super(props);
        this.identifier = this.props.match.params.identifier;

        this.state = {
            loading: false,
        };

        this.colors = ['#E91E63', '#3F51B5', '#FB8C00', '#673AB7', '#FF5722', '#0097A7', '#FF9800', '#689F38', '#9C27B0', '#CDDC39',
            '#E91E63', '#3F51B5', '#FB8C00', '#673AB7', '#FF5722', '#0097A7', '#FF9800', '#689F38', '#9C27B0', '#CDDC39',
            '#E91E63', '#3F51B5', '#FB8C00', '#673AB7', '#FF5722', '#0097A7', '#FF9800', '#689F38', '#9C27B0', '#CDDC39'
        ];

        this.admissionService = new AdmissionService();
    }

    componentDidMount() {
        this.setState({loading: true});

        this.admissionService.history(this.identifier).then(
            response => {
                let timelineEvents = []
                if (response.data.content) {
                    for (let i = 0; i < response.data.content.length; i++) {
                        let timeline = response.data.content[i];
                        timelineEvents.push({
                            title: I18n.t(timeline.step),
                            date: Intl.DateTimeFormat(this.props.locale, {dateStyle: 'full', timeStyle: 'medium'}).format(new Date(timeline.createdOn)),
                            icon: "pi " + timeline.icon,
                            description: timeline.description ? I18n.t(timeline.description) : "",
                            color: this.colors[i]
                        })
                    }
                }
                this.setState({
                    loading: true,
                    events: timelineEvents
                });
            }
        );
    }

    render() {
        const marker = (item) => {
            return (
                <span className="custom-marker p-shadow-2 p-p-2" style={{backgroundColor: item.color}}>
                <i className={classNames('marker-icon', item.icon)}></i>
            </span>
            );
        };

        const content = (item) => {
            return (
                <Card className="p-mb-3" title={item.title} subTitle={item.date}>
                    <p>{item.description}</p>
                    <Button label={I18n.t("DETAILS")} className="p-button-text"/>
                </Card>
            );
        };
        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 p-lg-4">
                    <div className="card  widget-timeline">
                        <Timeline value={this.state.events} align="left" className="customized-timeline" marker={marker} content={content}/>
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-lg-8">
                    <div className="card ">
                        docs details here
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    const accessToken = state.auth.account.accessToken;
    return {
        locale,
        accessToken
    };
}

export default connect(mapStateToProps)(ProjectDetails);
